<script setup lang="ts">
const auth = useAuth();
</script>

<template>
  <v-dialog v-if="auth.isWIFI && $route.path.endsWith('/form/wifi')" :max-width="480">
    <template #activator="{ props: activatorProps }">
      <div class="d-flex justify-center">
        <a
          class="tnc-btn"
          href="/authorize/form/wifi/tnc"
          v-bind="activatorProps"
          @click="(e) => e.preventDefault()">
          WI-FI Access Terms and Conditions
        </a>
      </div>
    </template>

    <template #default="{ isActive }">
      <v-card class="tnc-card">
        <template #title>
          <div class="d-flex align-center" style="justify-content: space-between">
            <div class="text-center truncate">WI-FI Access Terms and Conditions</div>
            <v-btn icon="mdi-close" color="contrast" size="2rem" @click="isActive.value = false" />
          </div>
        </template>
        <v-card-text class="py-4">
          <AuthWifiTncDetail @exit="isActive.value = false" />
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
  <div v-else class="text-xs d-flex justify-center my-2 my-sm-4 ga-1">
    <NuxtLink to="https://api.myvalue.id/pages/tnc" target="_blank">
      {{ $t('generic.termsAndConditions') }}
    </NuxtLink>
    <div style="font-size: 0.5rem; align-self: center">•</div>
    <NuxtLink to="https://www.myvalue.id/about" target="_blank">
      {{ $t('generic.privacyPolicy') }}
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.tnc-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 500 !important;
  text-decoration-line: underline;
  @media (min-width: $bpSm) {
    margin-top: 1rem;
  }
  :deep(.v-btn__content) {
    letter-spacing: initial !important;
  }
}

.text-center.truncate {
  flex: 1 1;
  font-size: 1rem;
  @media (min-width: $bpSm) {
    font-size: 1.25rem;
  }
}
</style>

<style>
.v-overlay__scrim {
  opacity: unset !important;
  background: rgba(var(--v-theme-on-surface), 0.5) !important;
  backdrop-filter: blur(12px);
}
</style>
