<script setup lang="ts">
const EXCLUDE = ['error', 'reset-pass', 'update-credentials'];

definePageMeta({ layout: 'myvalue-auth', middleware: 'sso-check-client' });

const gtm = useGTM();
const auth = useAuth();
const route = useRoute();

const fontName = computed(() => auth.uiConfig.fontName ?? 'Nunito Sans Variable');
const unrounded = computed(() => auth.uiConfig.buttonStyle !== 'FULL_ROUNDED');
const linkColor = computed(() => auth.uiConfig.secondaryColor || 'rgb(var(--v-theme-secondary))');

if (import.meta.client)
  useEventListener(parent, 'message', function (e) {
    const data = e.data as UIConfig | null;
    if (data?.name) auth.setUI(data);
  });

if (route.path === '/authorize/form') navigateTo('/authorize/error');
else if (!auth.client && EXCLUDE.includes(route.path)) navigateTo('/authorize/error');

const link = computed(() => auth.uiConfig && [{ rel: 'stylesheet', href: auth.uiConfig.fontUrl }]);
useHead({ link, htmlAttrs: { style: 'overflow: auto' } });

onMounted(() => {
  gtm.openLoginPage('loginNew');
  parent.postMessage({ loaded: true });
});
</script>

<template>
  <AuthCard>
    <NuxtPage :class="{ unrounded }" :transition="{ name: 'slide-left', mode: 'out-in' }" />
    <template #below>
      <AuthWifiTnc />
    </template>
  </AuthCard>
</template>

<style lang="scss">
a,
.link,
span[role='button'] {
  color: v-bind(linkColor) !important;
}

.unrounded .v-btn {
  border-radius: 0.5rem !important;
}
</style>

<style lang="scss">
html,
.auth {
  font-family: v-bind(fontName) !important;

  [class*='text-'] {
    font-family: v-bind(fontName) !important;
  }

  @media (min-width: $bpSm) {
    background: #fafafa;
  }
}
</style>
